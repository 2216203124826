import { Node } from "components/Drawer/NodeDrawer"
import Aside from "components/Layout/Aside"
import { useAddActiveToNodes } from "hooks/useScrollSpy"
import { t } from "locales"
import { useMemo } from "react"

import { IPage } from "data/contentData/api/content"
import getContentIds from "lib/get-sources-ids"

function useArticleAside(page: IPage) {
    const { sections, sources, references, authors } = page
    const { authorsContentId, referencesContentId } = getContentIds(page)

    let nodes = useMemo<Node[]>(() => {
        if (!sections) {
            return []
        }
        return sections
            ?.filter(section => section.type !== "root")
            ?.map(({ url, name, sections: mappedSections }) => {
                return {
                    name: name,
                    nodes: mappedSections?.map(
                        ({ name: mappedName, url: mappedUrl }) => ({
                            name: mappedName,
                            url: `#${mappedUrl}`
                        })
                    ),
                    url: `#${url}`
                }
            })
    }, [sections])

    if (sources) {
        const sourcesNodes = []

        if (references?.length) {
            sourcesNodes.push({
                name: t["article"]["references-title"],
                url: `#${referencesContentId}`
            })
        }

        if (authors?.length) {
            sourcesNodes.push({
                name: t["article"]["authors-title"],
                url: `#${authorsContentId}`
            })
        }

        const sourcesNode = {
            name: t["article"]["sources-title"],
            url: `#${sources.url}`,
            nodes: sourcesNodes
        }

        nodes = [...nodes, sourcesNode]
    }

    return nodes
}

interface IProps {
    page: IPage
}
function ArticleLeftAside({ page }: IProps) {
    const nodes = useArticleAside(page)
    const { activeElement, nodes: nodesWithActive } = useAddActiveToNodes(nodes)

    if (!page) {
        return null
    }

    return (
        <Aside
            activeElement={activeElement}
            node={{
                name: page.title,
                nodes: nodesWithActive,
                url: page.url
            }}
        />
    )
}

export default ArticleLeftAside
