import { PortableTextReactComponents } from "@portabletext/react"
import clsx from "clsx"
import PortableText from "components/PortableText/PortableText"
import Title from "components/Title"
import { ReactNode } from "react"

import { styled } from "@mui/material/styles"

import { ISection } from "data/contentData/api/content"

export interface SectionProps {
    section: ISection
    level?: number
    noPrint?: boolean
    children?: ReactNode
}

const customCollapsibleComponents: Partial<PortableTextReactComponents> = {
    block: {
        normal: ({ children }) => {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <>{children}</>
        }
    }
}

export default function DefaultPageSection({
    section,
    level = 2,
    children
}: SectionProps) {
    const isFactsSection = section.type === "facts"

    return (
        <StyledSection className={clsx(isFactsSection && "facts-section")}>
            <HeadingComponent
                section={section}
                level={level}
                isFactsSection={isFactsSection}
            />
            <div className={clsx(isFactsSection && "facts-content")}>
                <PortableText
                    value={section.blockContent}
                    wrapImgWithGalleryLink
                />
                {section.sections?.map(subSection => (
                    <DefaultPageSection
                        key={subSection.url}
                        level={level + 1}
                        section={
                            subSection as unknown as SectionProps["section"]
                        }
                    />
                )) ?? null}
            </div>
            {children}
        </StyledSection>
    )
}

const StyledSection = styled("section")(({ theme }) => ({
    marginTop: theme.spacing(2)
}))

function HeadingComponent({
    section,
    level,
    isFactsSection
}: {
    section: ISection
    level: number
    isFactsSection: boolean
}) {
    if (!section.heading && !section.name) {
        return null
    }

    const content = section.heading ? (
        <PortableText
            value={section.heading}
            customComponents={customCollapsibleComponents}
        />
    ) : (
        section.name
    )

    return (
        <Title
            id={section.url}
            level={level}
            className={clsx(isFactsSection && "facts-header")}>
            {content}
        </Title>
    )
}
