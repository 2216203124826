import { PortableTextReactComponents } from "@portabletext/react"
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
} from "@reach/disclosure"
import clsx from "clsx"
import PortableText from "components/PortableText/PortableText"
import { useSemiControlledExpand } from "hooks/useSemiControlledExpand"
import {
    ButtonHTMLAttributes,
    ElementType,
    ReactElement,
    ReactNode,
    Ref
} from "react"

// eslint-disable-next-line no-restricted-imports
import { Color } from "@mui/material"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"
// eslint-disable-next-line no-restricted-imports
import { Variant } from "@mui/material/styles/createTypography"

export interface CollapsibleProps {
    title: string | ReactElement
    id: string
    children: ReactNode
    heading?: any
    collapsed?: boolean
    level?: number
    titleComponent?: "span" // if we need other types, extend this
    onChange?: (...args: any[]) => void
    noPrint?: boolean
    isTopPanel?: boolean
    buttonRef?: Ref<HTMLButtonElement>
    DisclosureButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>
}

const customCollapsibleComponents: Partial<PortableTextReactComponents> = {
    block: {
        normal: ({ children }) => {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <>{children}</>
        }
    }
}

export default function Collapsible(props: CollapsibleProps) {
    const level = props.level ?? 1

    const semiControlledProps = useSemiControlledExpand(
        props.id,
        props.collapsed ?? false,
        props.onChange
    )

    return (
        <Disclosure
            defaultOpen
            id={props.id}
            {...semiControlledProps}>
            <StyledDisclosureButton
                level={level.toString()}
                ref={props.buttonRef}
                id={props.id}
                className="disclosure-header"
                {...props.DisclosureButtonProps}>
                <Typography
                    variant={`h${level}` as Variant}
                    component={
                        props.titleComponent ?? (`h${level}` as ElementType)
                    }
                    sx={{
                        "& p": {
                            margin: 0
                        }
                    }}>
                    {props.heading ? (
                        <PortableText
                            value={props.heading}
                            customComponents={customCollapsibleComponents}
                        />
                    ) : (
                        props.title
                    )}
                </Typography>
            </StyledDisclosureButton>
            <StyledDisclosurePanel
                sx={{
                    padding: !props.children ? 0 : undefined
                }}
                className={clsx(
                    "disclosure-panel",
                    props.isTopPanel && "top-panel"
                )}>
                {semiControlledProps.open ? props.children : null}
            </StyledDisclosurePanel>
        </Disclosure>
    )
}

const StyledDisclosureButton = styled(DisclosureButton)<{ level: string }>(
    ({ level, theme }) => {
        const { breakpoints, constants, palette, spacing, typography } = theme
        const isTopLevel = level === "2"
        const calculateBGColor = (offset = 0) =>
            grey[(100 * (parseInt(level) + offset)) as keyof Color]

        const variant = `h${level}` as Variant
        return {
            ...typography[variant],
            scrollMarginTop: `${
                constants.height.header + constants.height.headerXSExtra + 16
            }px`,
            [breakpoints.up("sm")]: {
                scrollMarginTop: `${constants.height.header + 16}`
            },
            [breakpoints.up("md")]: {
                scrollMarginTop: `${
                    constants.height.header + constants.height.breadcrumbs + 16
                }px`
            } as any,
            padding: spacing(1, 2, 1.25),
            cursor: "pointer",
            background: palette.grey[500],
            width: "100%",
            minHeight: 48,
            textAlign: "left",
            backgroundColor: isTopLevel
                ? palette.primary.main
                : calculateBGColor(),
            color: isTopLevel ? palette.primary.contrastText : "inherit",
            border: "none",
            borderTop: isTopLevel ? `1px solid ${palette.divider}` : undefined,
            boxShadow: "none",
            marginTop: spacing(1),
            display: "flex",
            gap: spacing(1),
            justifyContent: "space-between",
            alignItems: "center",
            "&:hover": {
                backgroundColor: isTopLevel
                    ? palette.primary.light
                    : calculateBGColor(1)
            },
            "&:focus": {
                outline: "none",
                boxShadow: `0 0 0 3px ${palette.primary?.dark}`,
                backgroundColor: isTopLevel ? palette.secondary.dark : "none"
            },
            "&::after": {
                fontSize: "0.8rem",
                marginRight: "0.25rem",
                transform: "translateY(-0.125rem) rotate(90deg)",
                transition: "150ms cubic-bezier(0.4, 0, 0.2, 1)",
                // https://github.com/mui-org/material-ui/issues/14153#issuecomment-453605145
                content: '"❯"',
                "@media print": {
                    display: "none !important"
                }
            },
            '&[data-state="open"]::after': {
                transform: "rotate(270deg)"
            },
            "@media print": {
                background: "transparent",
                color: "black !important"
            },
            // Headers with BlockContent have a p tag as a child
            "& > p": {
                margin: "0 !important"
            },
            "& > h4, > h4 > p, > span, > span > p": {
                margin: "0 !important",
                fontWeight: 700,
                fontSize: "1rem"
            }
        }
    }
)

// This exists to make sx-prop available: yuck!
const StyledDisclosurePanel = styled(DisclosurePanel)(() => ({}))
