import clsx from "clsx"
import { GalleryProvider } from "components/Gallery"
import Main from "components/Layout/Main"
import Meta from "components/Meta"
import { SkipNavContent } from "components/SkipNav"
import { t } from "locales"
import ArticleAuthorsSection from "modules/article/components/ArticleAuthorsSection"
import ArticleLeftAside from "modules/article/components/ArticleLeftAside"
import ArticleReferencesSection from "modules/article/components/ArticleReferencesSection"
import NonSubscriberSection from "modules/article/components/NonSubscriberSection"
import Paywall from "modules/article/components/Paywall"
import DrawerTools from "modules/article/components/Tools/DrawerTools"
import Tools from "modules/article/components/Tools/Tools"
import PageHeader from "modules/article/components/article-header/PageHeader"
import DefaultPageSection from "modules/article/templates/Default/DefaultPageSection"
import { useInteractions } from "modules/interactions/InteractionsContext"
import Image from "next/image"
import { noPrintSectionTypes } from "pages/[...page]"

import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import useEventualScroll from "@nhi/hooks/useEventualScroll"
import { IPage } from "data/contentData/api/content"

interface IProps {
    page: IPage
    isPublic?: boolean
}

export default function DefaultPageTemplate({ page, isPublic }: IProps) {
    useEventualScroll()
    const { showTools, closeTools } = useInteractions()

    const image = {
        alt:
            page.image?.alt ??
            page.image?.caption ??
            page.gallery?.[0]?.alt ??
            page.gallery?.[0]?.caption ??
            "",
        src: page.image?.src ?? page.gallery?.[0]?.src
    }

    return (
        <Main
            leftAside={<ArticleLeftAside page={page} />}
            rightAside={
                page.template?.hasTools ? (
                    <Tools tools={page.linkLists} />
                ) : null
            }>
            <DrawerTools
                onClose={closeTools}
                open={showTools}
                lists={page.linkLists}
            />

            <article>
                <Meta
                    noIndex={page.noIndex}
                    description={page.ingress}
                    image={image}
                    isPublic={isPublic}
                    title={page.title}
                    metadata={page.metadata}
                    type={"article"}
                    url={page.url}
                />
                <SkipNavContent />
                {page.image ? (
                    <StyledFigure className={clsx("no-print")}>
                        {/* REVIEW: Add alt text to images. (Maybe use same image format as in gallery) */}
                        <Image
                            priority
                            layout="intrinsic"
                            width={640}
                            height={360}
                            quality={80}
                            src={page.image}
                            alt={page.image.alt || page.image.caption}
                        />
                        <Typography
                            variant="caption"
                            component="figcaption"
                            sx={{ px: 2 }}>
                            {page.image.caption}
                        </Typography>
                    </StyledFigure>
                ) : null}
                <PageHeader page={page} />
                <StyledContentWrapper>
                    <Paywall page={page} />

                    {page.template?.showNonSubscriberInfo && (
                        <NonSubscriberSection page={page} />
                    )}

                    {/*
                     * Since it is block content,
                     * we cannot modify the margin of the anchor,
                     * the header will overlap when scrolling to it
                     * @see /handboken/kliniske-kapitler/endokrinologi/tilstander-og-sykdommer/diabetes-mellitus/diabetisk-nefropati
                     */}
                    <GalleryProvider gallery={page.gallery}>
                        {page.sections?.map(section => (
                            <DefaultPageSection
                                key={section.url}
                                section={section}
                                noPrint={noPrintSectionTypes.includes(
                                    section.type
                                )}
                            />
                        ))}
                        <Sources page={page} />
                    </GalleryProvider>
                </StyledContentWrapper>
                <StyledPrintDisclaimer variant="body2">
                    {`${t["on-print"]["disclaimer"]}`}
                </StyledPrintDisclaimer>
                <StyledPrintDisclaimer variant="body2">
                    {`${t["on-print"]["copy-right"].replace(
                        "$year",
                        new Date().getFullYear().toString()
                    )}`}
                </StyledPrintDisclaimer>
            </article>
        </Main>
    )
}

const StyledPrintDisclaimer = styled(Typography)(({ theme }) => ({
    display: "none",
    fontStyle: "italic",
    marginTop: theme.spacing(3),
    "@media print": {
        display: "block"
    }
}))

const StyledFigure = styled("figure")(() => ({
    margin: 0
}))

const StyledContentWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    position: "relative",
    [theme.breakpoints.up("sm")]: {
        padding: 0
    }
}))

interface ISourcesProps {
    page: IPage
}

function Sources({ page }: ISourcesProps) {
    if (!page.sources) {
        return null
    }

    const { url } = page.sources

    return (
        <DefaultPageSection
            key="sources"
            section={page.sources}
            noPrint={noPrintSectionTypes.includes(page.sources.type)}>
            {page.references && (
                <ArticleReferencesSection
                    id={`${url}-references`}
                    references={page.references}
                />
            )}
            {page.authors && (
                <ArticleAuthorsSection
                    id={`${url}-authors`}
                    page={page}
                />
            )}
        </DefaultPageSection>
    )
}
