import { Badge } from "components/UI/Badge"
import { general } from "config/config"

import { toLocalDate } from "@nhi/utils"

interface IProps {
    date?: Date
    content: string
}
export function RevisedOrNewBadge({ date, content }: IProps) {
    if (general.suppressNewAndRevisedBadges || !date) {
        return null
    }

    const now = new Date().getTime()
    const days = (now - new Date(date).getTime()) / (24 * 60 * 60 * 1000)

    if (days > 180) {
        return null
    }

    return (
        <Badge title={toLocalDate(date, process.env.NEXT_PUBLIC_LOCALE)}>
            {content}
        </Badge>
    )
}
