import Aside from "components/Layout/Aside"
import Main from "components/Layout/Main"
import Meta from "components/Meta"
import { SkipNavContent } from "components/SkipNav"
import usePath from "hooks/usePath"
import SitelistList from "modules/Sitelist/SitelistList"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { IPage } from "data/contentData/api/content"
import { CONTENT_TYPE, getContentType } from "lib/get-content-type"

export default function Sitelist({ page }: { page: IPage }) {
    const path = usePath()

    const articles =
        page.children?.filter(
            p => getContentType(p.types) !== CONTENT_TYPE.LIST
        ) ?? []
    const lists =
        page.children?.filter(
            p => getContentType(p.types) === CONTENT_TYPE.LIST
        ) ?? []

    const siblings =
        page.siblings
            ?.filter(p => getContentType(p.types) === CONTENT_TYPE.LIST)
            .map(({ name, shortcut, url }) => ({
                name,
                url: shortcut ?? url,
                active: false
            })) ?? []

    const activeSibling = siblings.find(p => p.url.startsWith(path))
    if (activeSibling) {
        activeSibling.active = true
    }

    const title = page.parents?.[0].name ?? page.name

    const listTitle = activeSibling?.name || title
    return (
        <Main
            leftAside={
                <Aside node={{ name: title, nodes: siblings, url: path }} />
            }>
            <Meta
                isPublic
                title={title}
                url={path}
            />

            <Grid container>
                <SkipNavContent />

                {activeSibling ? (
                    <Typography
                        variant="h1"
                        sx={{ pt: 1, mb: 2 }}>
                        {listTitle}
                    </Typography>
                ) : null}

                <SitelistList items={articles} />

                {lists.map(list => (
                    <SitelistList
                        key={list.contentId}
                        title={list.name}
                        items={list.children}
                    />
                ))}
            </Grid>
        </Main>
    )
}
