import SitelistItem from "modules/Sitelist/SitelistListItem"

import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPage } from "data/contentData/api/content"

export type SitelistListType = {
    title?: string
    items?: IPage[]
}

export default function SitelistList({ title, items }: SitelistListType) {
    if (!items?.length) {
        return null
    }

    return (
        <Grid
            item
            xs={12}>
            {title ? (
                <Grid container>
                    <Typography
                        gutterBottom
                        variant="h2">
                        {title}
                    </Typography>
                    <Grid
                        item
                        xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            ) : null}
            <StyledList listlength={items.length}>
                {items.map(item => (
                    <SitelistItem
                        key={item.url}
                        {...item}
                    />
                ))}
            </StyledList>
        </Grid>
    )
}

const StyledList = styled(List)<{ listlength: number }>(
    ({ listlength, theme }) => ({
        width: "100%",
        display: "grid",
        gridGap: theme.spacing(1),
        listStyle: "none",
        margin: theme.spacing(1, 0, 4, 0),
        padding: 0,
        ...(listlength > 3 && {
            [theme.breakpoints.up("md")]: {
                gridTemplateColumns: "repeat(2, minmax(250px, 1fr))",
                gridTemplateRows: `repeat(${Math.ceil(listlength / 2)}, 1fr)`,
                gridAutoFlow: "column dense"
            }
        })
    })
)
