import { Icon } from "components/Icon"
import Link from "components/Link"
import { useState } from "react"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { ILinkList } from "data/contentData/api/content"

interface DrawerToolProps {
    expanded?: boolean
    onClose(): void
    list: ILinkList
}

export default function DrawerTool(props: DrawerToolProps) {
    const { expanded = false, onClose, list } = props
    const [open, setOpen] = useState(expanded)
    const { icon, name, links } = list

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <StyledAccordion
            expanded={open}
            onChange={handleClick}>
            <Summary
                expandIcon={<Icon name="expandMore" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                {icon ? <Icon name={icon} /> : null}
                <Typography
                    variant="body1"
                    component="span"
                    sx={{ pl: 1, fontWeight: 700 }}>
                    {name}
                </Typography>
            </Summary>
            <AccordionDetails sx={{ py: 1, px: 0.5 }}>
                <List>
                    {links.map(link => (
                        <ListItem key={link.text}>
                            <Link
                                noWrap
                                color="secondary"
                                href={link.href}
                                title={link.text}
                                onClick={onClose}
                                sx={{ width: "100%" }}>
                                {link.text}
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </StyledAccordion>
    )
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    width: "100%",
    boxShadow: "none",
    marginBottom: theme.spacing(2),
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    },
    "&.Mui-expanded": {
        margin: "auto"
    }
}))

const Summary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(0.5, 2),

    "&.Mui-expanded": {
        minHeight: 48
    }
}))
